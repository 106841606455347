import React, { useState, useEffect } from "react";
import moment from "moment";
import ClipLoader from "react-spinners/ClipLoader";
import { Link } from "gatsby";

import requests from "../../../services/requests";
import Card from "../../../components/Cards";
import TitleC6 from "../../../components/Typography/TitleC6";

const Updates = filterInformation => {
    const [loadComments, setLoadComments] = useState(false);
    const [loadUpdates, setLoadUpdates] = useState(false);
    const [updatesList, setUpdatesList] = useState();
    const [commentsList, setCommentsList] = useState();
    const [typeUpdate, setTypeUpdate] = useState("comments");

    const actionType = type => {
        setTypeUpdate(type);
    };

    const listUpdates = () => {
        setLoadUpdates(true);
        requests
            .listUpdates(
                {
                    filters: filterInformation.filterInformation,
                },
                1,
                10
            )
            .then(response => {
                setUpdatesList(response.data);
                setLoadUpdates(false);
            });
    };

    const listComments = () => {
        setLoadComments(true);
        requests
            .lastComments(
                {
                    filters: filterInformation.filterInformation,
                },
                1,
                10
            )
            .then(data => {
                setCommentsList(data.data);
                setLoadComments(false);
            });
    };

    useEffect(() => {
        listUpdates();
        listComments();
    }, [filterInformation.filterInformation]);

    return (
        <Card className="w-2/5 md:ml-4 flex-shrink-0 flex-grow">
            <div className="flex flex-wrap justify-between">
                <TitleC6>{typeUpdate === "comments" ? "COMENTÁRIOS" : "ATUALIZAÇÕES"}</TitleC6>
                <div className="flex">
                    <button
                        className={`px-3 py-1 mr-2 ${
                            typeUpdate === "updates"
                                ? "bg-roxo_oficial text-white"
                                : "border border-roxo_oficial text-roxo_oficial"
                        } rounded `}
                        onClick={() => actionType("updates")}
                    >
                        atualizações
                    </button>
                    <button
                        className={`px-3 py-1 ml-2 ${
                            typeUpdate === "comments"
                                ? "bg-roxo_oficial text-white"
                                : "border border-roxo_oficial text-roxo_oficial"
                        } rounded `}
                        onClick={() => actionType("comments")}
                    >
                        comentários
                    </button>
                </div>
            </div>
            <div className="border-t mt-4 pt-4 border-C2 border-solid">
                {typeUpdate === "updates" && (
                    <div className="-mt-2 overflow-y-auto h-20rem px-2">
                        <>
                            {loadUpdates ? (
                                <div className="flex flex-col items-center">
                                    <ClipLoader size={20} />
                                </div>
                            ) : (
                                <>
                                    {updatesList &&
                                        updatesList.map((entity, key) => {
                                            return (
                                                <div
                                                    key={entity.identifier}
                                                    className="flex justify-between flex-wrap border-b border-C1 py-2"
                                                >
                                                    <Link
                                                        to={`/processos/detalhes/${entity.processIdentifier}?tab=update`}
                                                        state={{ prevPath: window.location.pathname }}
                                                    >
                                                        <div>
                                                            <h5>{entity.processCode}</h5>
                                                            <p
                                                                className="font-normal"
                                                                dangerouslySetInnerHTML={{ __html: entity.description }}
                                                            />
                                                        </div>
                                                        <legend className="whitespace-no-wrap">
                                                            {moment(entity.createdAt).format("HH:mm - DD MMM YYYY")}
                                                        </legend>
                                                    </Link>
                                                </div>
                                            );
                                        })}
                                    {updatesList?.length === 0 && (
                                        <div className="flex flex-col items-center">Sem atualizações</div>
                                    )}
                                    {!loadUpdates && (
                                        <div className="text-center mt-2">
                                            <Link to={`/processos/comentarios-e-atualizacoes`}>
                                                <strong className={`text-xs text-roxo_oficial`}>Ver mais</strong>
                                            </Link>
                                        </div>
                                    )}
                                </>
                            )}
                        </>
                    </div>
                )}
                {typeUpdate === "comments" && (
                    <div className="-mt-2 overflow-y-auto h-20rem px-2">
                        <>
                            {loadComments ? (
                                <div className="flex flex-col items-center">
                                    <ClipLoader size={20} />
                                </div>
                            ) : (
                                <>
                                    {commentsList &&
                                        commentsList.map(entity => (
                                            <Link
                                                key={entity.identifier}
                                                to={`/processos/detalhes/${entity.processIdentifier}?tab=comment`}
                                                state={{ prevPath: window.location.pathname }}
                                            >
                                                <div className="py-2 border-b border-C1">
                                                    <div className="flex flex-wrap items-center justify-between">
                                                        <h5>{entity.processCode}</h5>
                                                        <legend className="whitespace-no-wrap">
                                                            {moment(entity.createdAt).format("HH:mm - DD MMM YYYY")}
                                                        </legend>
                                                    </div>
                                                    <p className="font-normal">
                                                        <strong>{entity.userName}:</strong> {entity.description}
                                                    </p>
                                                </div>
                                            </Link>
                                        ))}
                                    {commentsList?.length === 0 && (
                                        <div className="flex flex-col items-center">Sem comentários</div>
                                    )}
                                </>
                            )}
                            {!loadComments && (
                                <div className="text-center mt-2">
                                    <Link to={`/processos/comentarios-e-atualizacoes`}>
                                        <strong className={`text-xs text-roxo_oficial`}>Ver mais</strong>
                                    </Link>
                                </div>
                            )}
                        </>
                    </div>
                )}
            </div>
        </Card>
    );
};
export default Updates;
